.bnCustomer {
    height: 100%;
    width: 100%;
}

.bnCustomerContent {
    height: calc(100% - 50px);
    width: calc(100% - 50px);
    padding: 25px;
}

.bnCustomerContentLocation {
}

.bnCustomerContentGenderAge {
    text-transform: uppercase;
    color: #6d6d6d;
    font-size: 13px;
    text-align: left;
    width: 100%;
    position: absolute;
    left: 35px;
}

.bnCustomerContentHeader {
    display: flex;
}

.bnCustomerContentLocationLine {
    margin-top: 5px;
    width: calc(100% - 30px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-left: 15px;
    margin-right: 15px;
}


.bnCustomerContentLocationIcon div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.bnCustomerContentLocationIcon svg {
    height: 20px;
}

.bnCustomerMenuSeparator {
    width: 15px;
}

.bnCustomerSearchResults {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 8px);
    margin-left: -4px;
}


.bnCustomerSearchResultsSort .bnTabviewTabs {
    justify-content: flex-end;
    width: 100%;
    flex-wrap: nowrap;
}

.bnProviderCategories .bnTabviewTabSeparator {
}

.bnProviderCategories .bnTabviewTabs {
    justify-content: flex-start;
}

.bnProviderCategories .bnTabviewTab {
    display: flex;
    justify-content: center;
    text-overflow: visible;
    white-space: nowrap;
    min-width: auto;
    max-width: calc(33.34% - 3 * 8.5px);
}

.benxtAppMobile .bnCustomerSearchResultsSort .bnTabviewTabs {
}

.bnCustomerSearchResultsSort .bnTabviewTab {
    min-width: auto;
    padding-left: 5px;
    padding-right: 5px;
}

.bnCustomerSearchResults .bnSearchResult {
    margin: 4px;
    width: calc(50% - 28px);
}

.bnDoctorCard {
    width: calc(100% - 50px);
    display: flex;
    flex-direction: column;
    padding: 25px;
    border-radius: 12.5px;
    background: white;
    filter: drop-shadow(0  0 8px  #dddddd);
    margin-bottom: 10px;
    padding-top: 10px;
}

.bnDoctorCardProfile {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-right: 2.5px;
    margin-letft: 2.5px;
    width: 40px;
}


.bnDoctorCardProfileIcon {
    height: 40px;
    width: 40px;
    background: gray;
    border-radius: 40px;
    margin-right: 4px;
}

.bnDoctorCardHeader {
    margin-top: 10px;
    display: flex;
    align-items: center;
}

.bnDoctorCardInfo {
    /* display: flex; */
    flex-direction: column;
    width: 100%;
}

.bnDoctorCardInfo svg {

}

.bnDoctorCardInfoLineEDU {

}

.bnEDULen3 {
    margin-left: 5px;
    width: calc(100% - 5px);
}

.bnEDULen4 {
    margin-left: 10px;
    width: calc(100% - 10px);
}

.bnDoctorCardInfoRatingLine {
    align-items: center;
    justify-content: flex-start;
}

.bnDoctorCardInfoRatingContainer {

}


.bnDoctorCardInfoRating {
    display: flex;
}

.bnDoctorCardInfoReviewRating {
    transform: scale(.75, .75);
    transform-origin: top left;
    column-gap: 3px;
}

.bnDoctorCardInfoText {
    display: flex;
    color: #005a7d;
    min-height: 20px;
}

.bnDoctorCardInfoIconText {
}

.bnDoctorCardReview {
    color: #005a7d;
    margin-top: 5px;
}

.bnDoctorCardName {
    color: #005a7d;
    margin-bottom: 5px;
}

.bnDoctorCardInfoLine {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 20px;
}

.bnDoctorCardInfoIcon {
    height: 20px;
    width: 20px;
}

.bnDoctorCardInfoIcon svg {
    height: 20px;
    width: 20px;
}

.bnDoctorCardInfoIcon div {
    height: 20px;
    width: 20px;
}

.bnDoctorCardRating {
    display: flex;
    margin-top: 5px;
    margin-bottom: 5px;
}

.bnDoctorCardRating svg {
    height: 20px;
}

.bnDoctorCardPrice {
    font-size: 16px;
    display: flex;
    font-weight: bold;
}

.bnDoctorCardFullPrice {
    color: rgb(240, 220, 0);
    font-size: 14px;
    vertical-align: super;
    text-decoration: line-through;
    margin-right: 5px;
}

.bnCustomerBookingWhen {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.bnBlueAnchor {
    color: rgb(23, 100, 192);
}

.bnCardElement {
    width: calc(100% - 30px);
    background: white;
    padding: 15px;
}

.bnCustomerAppointmentCard {
    filter: drop-shadow(0  0 8px  #dddddd);
    background: white;
    border-radius: 12.5px;
    width: calc(100% - 25px);
    padding: 12.5px;
    cursor: pointer;
}

.bnCustomerAppointmentCardHeader {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
}

.bnCustomerAppointmentCardHeaderTitle {
    color: rgb(245, 80, 0);
    font-weight: bold;
}

.bnCustomerAppointmentCardStatusTitle {
    margin-bottom: 10px
}

.bnCustomerAppointmentCardStatus {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.bnCustomerAppointmentCardProgress {
    display: flex;
    width: 100%;
    justify-content: space-between;
    height: 25px;
    background: rgb(224, 224, 224);
    height: 5px;
    margin-top: 15px;
    margin-bottom: 8px;
    border-radius: 5px;
}

.bnCustomerAppointmentCardProgressLabel1 {
    width: 0;
    height: 5px;
    border-radius: 5px;
    background: rgb(0, 186, 0);
}

.bnCustomerAppointmentCardStatusRequested .bnCustomerAppointmentCardProgressLabel1 {
    width: 25%;
}


.bnCustomerAppointmentCardStatusScheduled .bnCustomerAppointmentCardProgressLabel1 {
    width: 50%;
}

.bnCustomerAppointmentCardProgressLabels {
    color: rgb(224, 224, 224);
}

.bnCustomerAppointmentCardStatusBooked .bnCustomerAppointmentCardProgressLabel1 {
    width: 100%;
}


.bnCustomerAppointmentCardStatusRequested .bnCustomerAppointmentCardProgressLabelA {
    color: rgb(80, 80, 80);
}

.bnCustomerAppointmentCardStatusScheduled .bnCustomerAppointmentCardProgressLabelB {
    color: rgb(80, 80, 80);
}

.bnCustomerAppointmentCardStatusBooked .bnCustomerAppointmentCardProgressLabelC {
    color: rgb(80, 80, 80);
}

.bnCustomerAppointmentCardProgressLabels {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.bnCustomerAppointmentCardProgressLabel {
    display: flex;
    justify-content: center;
    width: 100%;
}

.bnCustomerAppointmentCardHeaderButton svg {
    height: 20px;
}

.bnCustomerAppointmentCardHeaderButton div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.bnCustomerAppointmentCardHeaderButton svg g path {
    fill: lightgray !important;
}

.bnCustomerAppointmentCardButtons {
    margin-top: 15px;
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.bnCustomerPaymentCard {
    background: white;
    padding-top: 10px;
    padding-left: 15px;
    padding-right: 15px;
    width: calc(100% - 30px);
}

.bnCustomerPaymentCard .bnInputFieldSeparator {
    margin-left: 0;
    width: 100%;
}


.bnLineItem {
    color: gray;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 25px;
    width: calc(100% - 25px);
}

.bnLineItemOrange {
    color: rgb(245, 90, 0);
    font-weight: bold;
}

.bnLineItemGreen {
    color: rgb(0, 190, 0);
    font-weight: bold;
}

.bnPaymentMethodLabelType {
    height: 25px;
    text-transform: uppercase;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-weight: bold;
    margin-right: 15px;
    width: calc(100% - 15px);
    font-size: 12px;
}

.bnPaymentMethodError {
    height: 25px;
    color: rgb(138, 0, 0);
    display: none;
}

.bnPaymentMethodLabelButton {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.bnPaymentMethodCard {
    background: white;
    display: flex;
    width: 100%;
    border-top: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;
}

.bnPaymentMethodCard .bnButton {
    margin-top: 10px;
}

.bnPaymentMethodButton {
    background: white;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    padding-right: 0px;
    padding-left: 15px;
    width: calc(100% - 15px);
}

.bnPaymentMethodButtonSelected .bnPaymentMethodLabelType {
    color: rgb(0, 190, 0);
}

.bnPaymentMethodButton svg {
    height: 20px;
    width: 20px;
    margin-right: 15px;
}

.bnPaymentMethodButton svg g path {
    fill: #6d6d6d !important;
}

.bnCustomerAppointmentCard .bnInputFieldSeparator {
    width: 100%;
    margin-left: 0;
}

.bnRevealDoctorInfo .bnOrangeLabel {
    min-height: auto;
}

.bnRevealDoctorInfo {
  color: #005a7d;
  margin-left: 10px;

}

.bnCustomerAppointmentCardDate {
    color: rgb(78, 78, 78);
    font-weight: bold;
    margin-top: 10px;
}

.bnCustomerAppointment {
    background: white;
}

.bnCustomerAppointment .bnPageButton {
    width: calc(100% - 30px);
}

.bnCustomerAppointment .bnOrangeLabel {
    padding-left: 15px;
}

.bnCustomerAppointment .bnFieldLabel {
    padding-left: 15px;
    width: calc(100% - 70px);
}

.bnCustomerAppointmentDoctorInfo {
    width: calc(100% - 30px);
    margin-left: 15px;
    margin-right: 15px;
}

.bnCustomerAppointmentAddress {
    padding: 5px;
    color: #6d6d6d;
}

.bnCustomerAppointmentButtons {
    width: calc(100% - 30px);
    padding: 15px;
}

.bnCustomerAppointmentButtonsRow2 {
    display: flex;
}

.bnPaymentMethodInput {
    font-size: auto;
}

.bnSpecialtyDescription {
    padding: 5px;
    padding-left: 0;
    color: #005a7d;
    margin-top: 5px;
    margin-bottom: 5px;
}

.bnDoctorCardInfoEdu .bnDoctorCardInfoText {
    font-size: 12px;
    margin-right: 1px;
    width: 20px;
    font-weight: bold;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 1px;
    min-height: 16px;
}

.bnDoctorCardInfoLineEDU  {
    align-items: flex-start;
}

.bnDoctorCardInfoLineEDU .bnDoctorCardInfoText {
    display: flex;
    align-items: flex-start;
}

.bnLocationButton {
    height: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    cursor: pointer;
    text-transform: uppercase;
    color: #6d6d6d;
    font-size: 13px;
}

.bnCustomerContentAdvancedSearchFilters {
}

.bnAutocompleteFilters {
    margin-top: 10px;
    margin-right: 15px;
}


.bnCustomerSearchResultCount {
    width: calc(100% - 0px);
    display: flex;
    margin-left: 0px;
    text-transform: uppercase;
    color: #6d6d6d;
    font-size: 13px;
    margin-bottom: 10px;
}

.bnCustomerRatingFilter .bnCheckboxes {
    background: transparent;
}

.bnCustomerRatingFilter .bnCheckbox {
}


.bnCustomerSpecialtiesFilterSelected {
    display: flex;
    flex-direction: column;
}

.bnCustomerSpecialtiesFilterSeparator {
    height: 1px;
    width: 100%;
}

.bnCustomerSearchBusy {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 75px;
}

.bnCustomerSearchBusySpinner {
    width: 80px;
}

.bnDoctorCardInfoRating {
}


.bnStarImg {
    max-height: 18px;
    max-width: 18px;
    margin-right: 1px;
}

.bnRatingText {
    height: 20px;
    display: flex;
    align-items: flex-end;
    margin-right: 2px;
}

.bnCustomerMenu {
    position: absolute;
    top: 60px;
    height: calc(100% - 60px);
    width: calc(100% - 50px);
    background: rgb(242, 252, 255);
    padding: 25px;
}

.bnMenuOpen {
    transform: rotate(-90deg);
}

.bnCustomerBookingBlurb1 {
    display: none;
}

.bnCustomerMenu {
    transform: translate(0px, -60px);
    height: calc(100% - 60px);
}

.bnCustomerMenuContainer {
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100vh;
}

.bnCustomerContentSearchResultsHeaderLine {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: baseline;
    margin-bottom: 5px;
}

.bnCustomerContentSearchResultsHeaderLine .bnLabel1 {
    margin-bottom: 0px;
}

.bnCustomerSearchFiltersButton {
    text-transform: uppercase;
    color: rgb(37, 158, 228);
    cursor: pointer;
}

.bnCustomerBookingContent .bnDoctorCardVisitReason .bnOrangeLabel {
    min-height: 24px;
}

.bnCustomerBookingContent .bnSpecialtyDescription {
    margin-bottom: 0;
    padding-bottom: 0;
}

.bnDoctorCardVisitReason {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.bnDoctorCardVisitReason .bnFieldTypeLabel {
    justify-content: flex-start;
}
