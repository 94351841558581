.bnDoctor {
    height: 100%;
    width: 100%;
}

.bnDates {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.bnDateInput input {
    margin-right: 0;
    padding-right: 0;
}

.bnServiceCard {
    background: white;
    margin-bottom: 15px;
}

.bnServiceLabel {
    display: flex;
}

.bnServiceLabelCode {
    width: 80px;
    margin-right: 12px;
    display: flex;
}

.bnDoctorAppointmentContainer {
    background: white;
    padding-bottom: 0px;
}

.bnDoctorAppointmentDoctorStandalone {
    padding-left: 20px;
    width: calc(100% - 25px);
}

.bnDoctorAppointmentDoctorContainer {
    background: white;
    width: 100%;
}

.bnDoctorAppointment {
    background: white;
    padding: 15px;
    padding-right: 0px;
    padding-left: 25px;
    padding-bottom: 0px;
    width: calc(100% - 25px);
}

.bnDoctorAppointment .bnInputFieldSeparator {
    width: 100%;
    margin-left: 0;
}


.bnDoctorAppointmentButtons {
    display: flex;
    margin-left: 5px;
    width: calc(100% - 10px);
    margin-top: 10px;
    justify-content: space-between;
    padding-bottom: 10px;
}

.bnDoctorAppointmentWhen {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.bnDoctorAppointmentWhen input {
    border: none;
    cursor: pointer;
    color:  rgb(245, 90, 0);
    font-weight: bold;
    text-align: right;
    font-size: 16px;
}

.bnDoctorAppointmentWhen input:focus {
    outline: none;
}

.bnDoctorAppointment .bnPageButton {
    padding-left: 0px;
}

.bnDoctorAppointment .bnPageButtonLabel {
    margin-left: 0px;
}

.bnDoctorAppointment .bnFieldLabel {
    margin-left: 0;
    padding-left: 0;
    width: calc(100% - 35px);
}

.bnDoctorAppointmentNotes {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 25px;
    width: calc(100% - 25px);
}

.bnPracticeCard {
    background: white;
    width: calc(100% - 20px);
    padding-left: 20px;
    padding-right: 0px;
    padding-bottom: 15px;
    border-top: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;
}

.bnPracticeCard .bnOrangeLabel {
    font-size: 14px;
    min-height: 25px;
}

.bnPracticeCard .bnOrangeLabelText {
}

.bnPracticeCard .bnLabel3 {
    transform: translate(-2px, 0);
}

.bnPracticeCardAddress {
    margin-top: 3px;
    color: rgb(30, 30, 30);
    margin-bottom: 15px;
}

.bnPhoneNumber {
    color: rgb(30, 90, 200);
    text-decoration: none;
}

.bnPracticeCard .bnPhoneNumber {
    height: 20px;
    display: flex;
    align-items: center;
}

.bnPracticeCard .bnLabel3 {
    min-height: 20px;
}

.bnFormHack {
    background: white;
    padding-left: 15px;
    width: calc(100% - 20px);
}

.bnDoctorAppointmentReplaceButton {
    width: calc(100% - 15px);
    margin-bottom: 10px;
    display: flex;
}

.bnDoctorAppointmentDoctor .bnBoolInputLabel {
    margin: 0;
}

.bnDoctorAppointmentDoctorStandalone .bnFieldLabel {
    padding-left: 0;
}

.bnDoctorAppointmentDoctor .bnDates {
    width: calc(100% - 15px);
    padding-left: 0;
    padding-right: 0;
}

.bnDoctorAppointmentAffiliation {
    color: rgb(60, 60, 60);
    font-weight: 550;
    max-width: calc(100% - 10px);
    margin-top: 5px;
}
