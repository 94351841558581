.accordion-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    padding: 20px;
    background-color: #FFFFFF;
}

.benxtAppDesktop .accordion-title {
    padding-left: 40px;
    padding-right: 40px;
}

.accordion-title:hover {
    background-color: #F2F2F2;
}

.accordion-content {
    font-family: "apparat-light", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 15px;
    padding-top: 5px;
    padding-bottom: 20px;
    padding-left: 40px;
    padding-right: 40px;

    background-color: #FFFFFF;
}

.benxtAppDesktop .accordion-content {
    font-size: 18px;
    padding-left: 40px;
    padding-right: 60px;
}