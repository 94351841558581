
.bnInputFieldInput {
    font-size: 16px;
    background: transparent;
    border: 0;
    outline: none;
    padding: 0;
    padding-left: 15px;
    width: calc(100% - 15px);
    color: #6d6d6d;
}

.bnInputField {
    height: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    background: white;
}

.bnInputField input::placeholder {
    color: #c4c4c4;
}

.bnInputFieldPhoneInput {
    outline: none;
    border: none;
    padding-left: 15px;
    font-size: 16px;
    width: calc(100% - 15px);
    color: #6d6d6d;
}

.bnInputField input:focus {
    outline: none;
}

.bnInputFieldSeparator {
    width: calc(100% - 15px);
    height: 1px;
    margin-left: 15px;
    background: #eeeeee;
}

.bnInputFieldErr {
    background: rgb(255, 183, 173);
}

.bnInputFieldErr input::placeholder {
    color: #6d6d6d !important;
}

.bnSearchField {
    display: flex;
    width: 100%;
    height: 50px;
    background: white;
    border-radius: 25px;
    align-items: center;
    border: 1px solid #eeeeee;
    filter: drop-shadow(0  0 2px  #dddddd);
    border: 0;
}

.bnSearchFieldInput {
    width: calc(100% - 80px);
    margin-left: -5px;
}

.bnSearchFieldIcon.bnBusy {
}

.bnSearchFieldIcon.bnBusy svg {
    transform: scale(2.25, 2.25);
}

.bnSearchFieldIcon.bnBusy #Layer_5 {
    transform-box: view-box;
    transform-origin: center center;
    animation: spinx 1s linear infinite;
}

.bnSearchFieldIcon.bnBusy svg g path {
    stroke: #aaaaaa;    
}

.bnSearchField .spinner-cls-2 {
}

.bnSearchFieldInput input {
    max-width: none;
    width: 100%;
    font-size: 16px;
    border: 0;
    color: rgb(60, 60, 60);
}

.bnSearchFieldInput input:focus {
    outline: none;
}

.bnSearchFieldInput input::placeholder {
    color: #c4c4c4;
}

.bnSearchFieldIcon {
    padding-left: 15px;
    padding-right: 15px;
    height: 50px;
}

.bnSearchFieldIcon div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.bnSearchFieldIcon svg {
    height: 20px;
}

.bnSearchFieldIcon {
    display: flex;
    justify-content: center;
    align-items: center;
}

.bnBoolInput {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: white;
    width: 100%;
    height: 40px;
}

.bnNegInput .mbsc-ios.mbsc-switch input:checked+.mbsc-switch-track {
    background: rgb(138, 0, 0) !important;
    border-color: rgb(138, 0, 0) !important;
}

.bnBoolInputLabel {
    color: #6d6d6d;
    margin-left: 15px;
    font-size: 16px;
}

.bnBoolInput .mbsc-switch {
    height: 10px;
}

.bnCurrencyInput {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding: 8px;
    padding-left: 0;
    padding-right: 20px;
    width: calc(100% - 20px);
    background: white;
    height: 20px;
    font-size: 16px;
}

.bnCurrencyInputLabel {
    color: #6d6d6d;
    margin-left: 15px;
    white-space: nowrap;
}

.bnCurrencyInputInput {
    text-align: right;
    border: 0;
    outline: none;
    font-weight: bold;
    color: rgb(245, 80, 0);
    font-size: 16px;
    width: 80px;
}

.bnCurrencyInputInput:focus  {
    color: white;
    background: rgb(245, 80, 0);
}

.bnCurrencyInputInput::selection {
    color: white;
    background: rgb(245, 80, 0);
}

.bnTextArea textarea {
    border: none;
    resize: none;
    width: 100%;
    min-height: 80px;
    font-size: 16px;
    
}

.bnTextArea textarea:focus {
    outline: none;
}

.bnDateInput input {
    margin-right: 0;
    padding-right: 0;
    border: 0;
    color: rgb(245, 90, 0);
    font-size: 16px;
    font-weight: bold;
    text-align: right;
    cursor: pointer;
}

.bnDateInput input:focus {
    outline: none;
}
