.bnSignIn {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.bnSignInLogo {
    margin-bottom: 25px;
}

.bnSignInFields {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    border: 1px solid #eeeeee;
    margin-top: 5px;
}

.bnSignInContent {
    margin-top: 25px;
    width: calc(100% - 50px);
    margin-left: 25px;
    margin-right: 25px;
}

.bnSignInLogo svg {
    height: 30px;
}

.bnSignInRecoverPasswordLine {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}

.bnSignInRecoverPassword {
    text-decoration: underline;
    font-size: 13px;
    color: rgb(0, 114, 145);
    cursor: pointer;
}

.bnSignInContinue {
    margin-top: 50px;
}

.bnSignInErr {
    color: rgb(184, 38, 26);
    margin-left: 15px;
    margin-top: 5px;
    height: 40px;
}
