.bnCustomerAccountSetup {
    height: 100%;
    width: 100%;
}

.bnCustomerAccountSetupPage {
    height: 100%;
    width: 100%;
}

.bnCreatingAccountProgress {
    margin-top: 20%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bnCreatingAccountProgress div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.bnCreatingAccountProgress svg {
    width: 50%;
}
