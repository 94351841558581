.bnApp {
    height: 100%;
    width: 100%;
}

.benxtAppMobile .bnAppSafeArea {
    position: absolute;
    top: env(safe-area-inset-top);
    height: calc(100% - env(safe-area-inset-top));
}

#recaptcha-fun {
   display: none
}

.bnSignInChoice {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    background: rgb(245, 90, 0);
    color: white;
    height: 30px;
    align-items: center;
    margin-bottom: 5px;
}


.bnSplashImage {
    width: 100%;
}

.benxtAppMobile .bnSplashImage img {
    position: fixed;
    top: -env(safe-area-inset-top);
    width: 100vw;
    height: 100%;
    object-fit: cover;
}


    
