.bnAdmin {
    height: 100%;
    width: 100%;
}

.bnServiceButton .bnPageButtonLeft .bnPageButtonIcon svg g path {
    fill: rgb(0, 88, 120) !important;
}

.bnServiceButton .bnPageButtonLabel {
    color: rgb(0, 88, 120);
}

.bnAdminAddSpecialty .bnButton {
}

.bnReasonCard{
    background: white;
    margin-bottom: 10px;
}

.bnReasonCard .bnFieldTypeLabel {
    margin-right: 15px;
    margin-top: 2px;
    margin-bottom: -5px;
}

.bnReasonButton .bnPageButton2Title {
    color: rgb(245, 80, 0);
    font-weight: bold;
    font-size: 16px;
}

.bnReasonCardLabel {
    color: rgb(245, 80, 0);
    margin: 10px;
    font-size: 16px;
    margin-left: 15px;
    font-weight: bold;
}

.bnSearchResultSummary {
    margin-top: 10px;
    width: 100%;
    text-align: right;
    color: #444444;
    margin-bottom: 5px;
}

.bnSearchFilters {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.bnSearchFiltersActive {
    margin-top: 0px;
    width: 100%;
}

.bnSpecialtyCard {
    background: white;
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.bnSpecialtyItem {
    color: #444444;
    padding: 10px;
    background: white;
}

.bnCardFormButton {
    width: calc(100% - 30px);
    padding: 15px;
    padding-top: 0;
}
