
html {
  height: 100%;
  width: 100%;    
  position: absolute;
  bottom: 0;
  margin: 0;
  font-family:  OpenSans, Arial, Helvetica, sans-serif;
  -webkit-text-size-adjust: 100%;
}

body, #root{
  height: 100%;
  width: 100%;    
  position: fixed;
  margin: 0;
  font-family:  OpenSans, Arial, Helvetica, sans-serif;
  height: calc(100% - env(safe-area-inset-top) - env(safe-area-inset-bottom));
  width: calc(100% - env(safe-area-inset-left) - env(safe-area-inset-right));
  scrollbar-width: thin;
  background: #17043d;
  height: 100%;
  font-size: 14px;
}

.uiClientBlockInput {
    touch-action: none;
}



.uiTeTeAppWindows *::-webkit-scrollbar {
    width: 4px;
}

.uiTeTeAppWindows *::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px #cccccc;
}

.uiTeTeAppWindows *::-webkit-scrollbar-thumb {
    background-color: #dddddd;
}

.uiTeTeAppWindows {
    scrollbar-width: thin !important;
}

