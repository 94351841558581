.bnButton {
    border-radius: 10px;
    height: 45px;
    color: white;
    background: rgb(0, 190, 0);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    cursor: pointer;
    width: calc(100% - 30px);
    overflow: hidden;
}

.bnModifyButton {
    border-radius: 10px;
    background: rgb(0, 90, 128);
    height: 45px;
    width: 100%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.bnCancelButton {
    border-radius: 10px;
    background: rgb(138, 0, 0);
    height: 45px;
    width: 100%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}


.bnButtonLabel {
    font-size: 16px;
    font-weight: bold;
}

.bnButtonIcon {
    font-size: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    overflow: hidden;
    position: relative;
}

.bnButtonIcon div  {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0;
}

.bnButtonIcon svg {
    height: 20px;
    width: 20px;
}

.bnClickable {
    cursor: pointer;
}


.bnButtonBusy {
    position: relative;
}

.bnButtonBusy .bnButtonIcon {
    width: 42px;
    height: 12px;
}

.bnButtonBusy .bnButtonIcon div {
}

.bnButtonBusy svg {
    height: 100%;
    width: 100%;
}


.bnButtonOof {
    background: rgb(184, 38, 26);

}

.bnBackButton {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.bnBackButton div {
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(60, 60, 60);
}

.bnBackButtonIcon svg {
    height: 20px;
}

.bnBackButtonIcon svg g path {
    fill: rgb(60, 60, 60) !important;
}

.bnMenu svg {
    height: 20px;
}

.bnLogoSmall svg {
    height: 20px;
}

.bnSearchResult {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 12.5px;
    background: white;
    width: calc(100% - 20px);
    padding: 10px;
    border: 1px solid #eeeeee;
    margin-bottom: 8px;
    filter: drop-shadow(0  0 8px  #dddddd);
    border: 0;
    min-height: 25px;
    cursor: pointer;
}

.bnSearchResultIcon {
    min-width: 25px;
}

.bnSearchResultIcon div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.bnSearchResultIcon svg g path {
    fill: #6d6d6d !important;
}

.bnSearchResultLabel {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: #6d6d6d;
}

.bnSearchResultBack {
    justify-content: flex-start;
}

.bnSearchResultIcon svg {
    height: 20px;
}

.bnPageButton {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    cursor: pointer;
    padding: 5px;
    font-size: 16px;
}

.bnPageButtonRight {
    display: flex;
    align-items: center;
}

.bnPageButtonLeft {
    display: flex;
    align-items: center;
}

.bnPageButtonCount {
    color: rgb(245, 90, 0);
    font-size: 16px;
    font-weight: bold;
}

.bnPageButtonLabel {
    color: rgb(104, 104, 104);
    margin-left: 10px;
}

.bnPageButtonWithIcon .bnPageButtonLabel {
    margin-left: 0px;
}

.bnPageButtonIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
}

.bnPageButtonIcon svg {
    height: 20px;
}

.bnPageButtonIcon div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.bnPageButtonIcon svg g path {
    fill: rgb(181, 181, 181) !important;
}


.bnInputWithButton {
    display: flex;
    align-items: center;
}

.bnSimpleButton {
    height: 30px;
    width: 120px;
    color: white;
    background: black;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    cursor: pointer
}

.bnButtonBlue {
    background: rgb(0, 91, 128);
}

.bnButtonRed {
    background: rgb(138, 0, 0);
}

.bnBlueButton .bnButton {
    background: rgb(0, 90, 128);
}

.bnRedButton .bnButton {
    background: rgb(138, 0, 0);
}

.bnRedButton {
    width: 100%;
}

.bnBlueButton {
    width: 100%;
}


.bnPageButton2 {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    background: white;
    width: calc(100% - 15px);
    padding: 5px;
    padding-bottom: 10px;
    padding-left: 15px;
    position: relative;
    padding-top: 15px;
}

.bnPageButton2 .bnFieldTypeLabel {
    position: absolute;
    top: 3px;
    right: 20px;
}

.bnPageButton2TitleText {
    width: 100%;
}

.bnPageButton2Row1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.bnPageButton2Row2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #6d6d6d;

}

.bnTabview {
    width: 100%;
}

.bnTabviewTabs {
    display: flex;
    flex-wrap: wrap;
    column-gap: 8px;
}

.bnTabviewTab {
    display: flex;
    cursor: pointer;
    border-radius: 15px;
    filter: drop-shadow(0  0 8px  #dddddd);
    background: white;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    width: calc(100% - 20px);
    min-width: calc(min(80px, 33%));
    margin-bottom: 8px;
    justify-content: center;
    font-size: 11px;
    font-weight: bold;
    color: #888888;
    max-width: calc(33.34% - 3 * 8.5px);
}

.bnTabviewSelected.bnTabviewTab {
    background: rgb(245, 80, 0);
    cursor: default;
    color: white;
}


.bnCheckboxes {
    display: flex;
    width: 100%;
    height: 26px;
}

.bnCheckbox {
    cursor: pointer;
    width: 100%;
    background: rgb(226, 226, 226);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    white-space: nowrap;
    filter: drop-shadow(0  0 8px  #dddddd);
    border-radius: 15px;
    background: white;
    color: #888888;
}

.bnCheckboxIcon {
    display: flex;
}
.bnCheckboxIcon svg {
    height: 20px;
    width: 20px;
}
    

.bnCheckboxSeparator {
    width: 8px;
    min-width: 8px;
}

.bnCheckboxSelected {
    background: rgb(245, 80, 0);
    color: white;
}

.bnSelectionListItem {
    color: #444444;
    padding: 10px;
    background: white;
    cursor: pointer;
}

.bnSelectionListItemSelected {
    background: rgb(245, 80, 0);
    color: white;
}

.bnPageButtons {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.bnOKButton {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 15px;
}

.bnOKButtonLabel {
    color: rgb(0, 90, 128);
    font-weight: bold;
}

.bnOKButtonIcon {
    display: none;
}

.bnButtonBusy .bnOKButtonLabel {
    display: none;
}

.bnButtonBusy .bnOKButtonIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 12px;
}

.bnOKButtonIcon div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.bnOKButtonIcon svg {
    height: 20px;
}

.bnOKButtonIcon svg g path {
    fill: rgb(0, 90, 128) !important;
}

.bnRadioButton {
    display: flex;
   align-items: center;
}


.bnRadioButtonButton {
    height: 35px;
    width: 35px;
    padding: 5px;
    position: relative;
}

.bnRadioButtonOuter {
    height: 30px;
    width: 30px;
    border-radius: 30px;
    border: 2px solid rgb(245, 90, 0);
}

.bnRadioButtonInner {
    height: 25px;
    width: 25px;
    border-radius: 25px;
    background: rgb(245, 90, 0);
    position: absolute;
    top: 9.5px;
    left: 9.5px;
}

.bnButtonSeparator {
    width: 15px;
    height: 100%;
}

.bnColumnButtonSeparator {
    height: 15px;
    width: 100%;
}

.bnMemberRemoveButton {
    width: 30px;
    height: calc(100% - 10px);
    background: rgb(138, 0, 0);
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
    min-height: 50px;
}

.bnMemberButton {
    display: flex;
    height: 70px;
}


.bnMemberRemoveButton svg {
    height: 20px;
}

.bnMemberRemoveButtonBusy {
}

.bnMemberRemoveButtonBusy .bnMemberRemoveButtonIcon {
    transform: translate(0, 12.5px) rotate(-90deg);
    width: 42px;
    height: 12px;
}

.bnMemberRemoveButtonIcon {
    height: 20px;
    width: 20px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bnMemberRemoveButtonIcon div {
    display: flex;
    align-items: center;
    justify-content: center;
}


.bnMemberRemoveButtonBusy svg {
   width: 42px;
   transform-box: view-box;
   transform-origin: center center;
}

.bnTabviewTabSeparator {
    width: 8px;
    min-width: 8px;
    display: none;
}

