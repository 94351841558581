.bnBusiness {
    height: 100%;
    width: 100%;
}


.bnPlan {
    background: white;
    width: calc(100% - 50px);
    padding: 25px;
    margin-bottom: 20px;
}

.bnPlanPrice {
    font-size: 14px;
    color: #888888;
    
}

.bnPlanDollars {
    color: rgb(40, 40, 40);
    font-size: 16px;
    font-weight: bold;
}

.bnPlan .bnButton {
    margin-top: 45px;
    background: rgb(0, 90, 128);
}

.bnPlanTitle {
    display: flex;
    align-items: center;
    color: rgb(40, 40, 40);
    overflow: hidden;
}

.bnPlanName {
    text-transform: uppercase;
    font-size: 22px;
    font-weight: bold;
    margin-right: 15px;
}

.bnPlanTitle svg {
    height: 30px;
}


.bnBusinessAccountPaymentMethod {
    width: 100%;
    background: white;
}


